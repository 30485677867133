<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" v-if="profile == 3">
        <v-card class="mt-4">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="h6" v-bind="attrs" v-on="on"
                  ><h4>
                    Ajout d’une séance d’évaluation des Offres/Propositions/Plis
                  </h4></span
                >
              </template>
              <span>Ajout d’une séance d’évaluation des Offres/Propositions/Plis</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <template>
              <v-container fluid>
                <v-radio-group v-model="Dtype" row>
                  <v-radio
                    label="Créer une séance d’évaluation des Offres/Propositions/Plis"
                    v-bind:value="false"
                  ></v-radio>
                  <v-radio
                    label="Créer une séance d’évaluation sur les réponses à un avis à manifestation d’intérêts/ un avis de pré-qualification"
                    v-bind:value="true"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </template>

            <v-text-field
              v-model="evaluation.title"
              name="input-7"
              label="Saisir le titre de la séance d’évaluation"
            ></v-text-field>
            <v-autocomplete
              v-if="!Dtype"
              v-model="evaluation.document"
              :items="this.$store.getters['Auth/getlistSubmissionFoldNoti'].docs"
              item-text="object"
              v-on:change="getDocType()"
              item-value="id"
              outlined
              label="Sélectionner le dossier correspondant à la séance d’évaluation"
            ></v-autocomplete>
            <v-autocomplete
              v-if="Dtype"
              v-model="evaluation.document"
              :items="this.$store.getters['Auth/getlistSubmissionFoldNoti'].notice_ami_apq"
              item-text="object"
              item-value="id"
              outlined
              label="Sélectionner l'avis à evaluer"
            ></v-autocomplete>
            <date-picker
              label="Date de Publication"
              v-model="evaluation.date"
              value-type="format"
              class="mb-2"
              format="YYYY-MM-DD H:m:s"
              :default-value="new Date()"
              type="datetime"
              placeholder="Renseigner la date/l’heure de la séance d’évaluation"
            ></date-picker>
            <v-autocomplete
              v-if="this.$store.getters['Auth/getCom']"
              v-model="evaluation.com"
              :items="
                this.$store.getters['Auth/getCom'].filter(function(value, index, arr) {
                  return value.type.status == 2;
                })
              "
              item-text="title"
              item-value="id"
              outlined
              label="Choisir la commission d’évaluation en fonction de l’objet du dossier concerné"
            ></v-autocomplete>

            <v-btn color="primary" small @click="addEvaluation" class="white--text" block>
              <v-icon small dark>mdi-card-bulleted-settings</v-icon> PROGRAMMER LA SEANCE D’EVALUATION
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :md="dynamicCol">
        <v-col>
          <v-card class="mx-auto">
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on"
                    ><h4>Liste détaillée des séances d’évaluation des Offres/Propositions/Plis/Manifestations d’Intérêts/Pré-qualifications</h4></span
                  >
                </template>
                <span
                  >Liste de programmation des séances d'évaluations des offres/propositions/plis ou
                  réponses des MI / APQ
                </span>
              </v-tooltip>
              <v-spacer></v-spacer
              ><v-chip class="ma-2">{{
                this.$store.getters["Auth/get_all_evaluation"].length
              }}</v-chip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-virtual-scroll
                :items="this.$store.getters['Auth/get_all_evaluation']"
                :item-height="50"
                height="400"
              >
                <template v-slot="{ item }">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large>mdi-card-bulleted-settings</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.date | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn @click="seemeeting(item)" depressed small>
                        Acceder
                        <v-chip outlined label class="mx-1" v-if="item.evalFin">SPF</v-chip>
                        <v-chip label outlined class="mx-1" v-if="item.evalTech">SPT</v-chip>
                        <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";

export default {
  components: {
    DatePicker
  },
  data: () => ({
    docTypeEval: [],
    dynamicCol: 6,
    evaluation: {
      title: "",
      com: 0,
      document: 0,
      date: null
    },
    Dtype: false
  }),
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    this.$store.dispatch("Auth/getSubmissionFoldNoti");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    if (this.profile == 4) {
      this.dynamicCol = 12;
    }
    this.$store.dispatch("Auth/loadAllNotice");
    this.$store.dispatch("Auth/loadAllDoc");
    this.$store.dispatch("Auth/loadAllEvaluation");
    this.$store.dispatch("Auth/loadAllCom");
  },
  methods: {
    ...mapActions({
      extension_addN: "Auth/extension_addN"
    }),
    addEvaluation() {
      const data = new FormData();
      if (this.evaluation.title) {
        data.append("title", this.evaluation.title);
        if (this.Dtype) {
          data.append("notice", this.evaluation.document);
          data.append("eval_type", 1);
        } else {
          data.append("document", this.evaluation.document);
          // console.log(this.docTypeEval.status);
          if (this.docTypeEval.status == 4) {
            data.append("eval_type", 2);
          } else {
            data.append("eval_type", 1);
          }
        }
        data.append("commission", this.evaluation.com);
        data.append("date", this.evaluation.date);
        this.$store.dispatch("Auth/addEvaluation", data);
      }
    },
    async getDocType() {
      console.log(this.evaluation.document);
      let doc = [];
      await this.$store.dispatch("Auth/getDoc", this.evaluation.document);
      doc = this.$store.getters["Auth/get_selected_doc"].document;
      this.docTypeEval = doc.documentType;
      // console.log(this.docTypeEval);
    },
    seemeeting(item) {
      //console.log(item);
      if (new Date() <= new Date(item.date)) {
        Swal.fire({
          title: "Attention !",
          text: `La seance d'evaluation est prévue pour le ${moment(item.date).format(
            "dddd, Do MMMM YYYY à HH:mm:ss A"
          )}`,
          icon: "error"
        });
      } else {
        this.$router.push({ name: "showEvaluation", params: { id: parseInt(item.id) } });
      }
    }
  }
};
</script>
<style>
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
