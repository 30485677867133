<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>Information sur la séance d’évaluation des Offres/Propositions/Plis/Manifestations d’Intérêts/Pré-qualifications concernées</h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Titre de la séance d’évaluation :</b>
            {{ this.$store.getters["Auth/get_selected_evaluation"].eval.title }}
            <v-spacer></v-spacer>
            <b>Date/heure de la séance d’évaluation :</b>
            {{
              this.$store.getters["Auth/get_selected_evaluation"].eval.date
                | moment("dddd, Do MMMM YYYY")
            }}
            <v-spacer></v-spacer>
            <b>Intitulé de la commission d’ouverture :</b>
            {{ this.$store.getters["Auth/get_selected_evaluation"].session.firstCommission.title }}
            <v-spacer></v-spacer>
            <b>Intitulé de la commission d’évaluation :</b>
            {{ this.$store.getters["Auth/get_selected_evaluation"].eval.com.title }}
            <v-spacer></v-spacer>
            <b>Lien de la visioconférence pour accéder à la séance d’évaluation :</b>
            {{ this.$store.getters["Auth/get_selected_evaluation"].eval.link }}
            <v-spacer></v-spacer>
            <span>
              <b> Nombre total des Offres/Propositions/Plis/Manifestations d’Intérêts/Pré-qualifications reçues  à évaluer :</b>
              <v-chip class="primary ma-2">
                {{ this.$store.getters["Auth/get_selected_evaluation"].offerList.length }}
              </v-chip>
            </span>
          </v-card-text>
        </v-card>
        <div v-if="this.$store.getters['Auth/get_selected_evaluation'].eval.document">
          <v-card
            v-if="profile == 3 && this.$store.getters['Auth/get_selected_evaluation'].eval.evalTech"
            class="mt-2"
          >
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on">
                    <h4>Programmation de la séance d’évaluation des propositions financières</h4></span
                  >
                </template>
                <h4>Programmation de la séance d’évaluation des propositions financières</h4>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                v-model="evaluation.dp_selection_quality"
                :items="this.AllFinancialType"
                item-text="object"
                item-value="id"
                outlined
                label="Sélectionner la méthode de sélection du consultant"
              ></v-autocomplete>
              <v-autocomplete
                v-model="evaluation.com"
                v-if="this.$store.getters['Auth/getCom']"
                :items="
                  this.$store.getters['Auth/getCom'].filter(function(value, index, arr) {
                    return value.type.status == 2;
                  })
                "
                item-text="title"
                item-value="id"
                outlined
                label="Choisir la commission d’évaluation en fonction de l’objet du dossier concerné"
              ></v-autocomplete>
              <v-text-field
                v-model="evaluation.title"
                name="input-7"
                label="Saisir le titre de la séance d’évaluation"
              ></v-text-field>
              <date-picker
                v-model="evaluation.date"
                label="Renseigner la date/l’heure de la séance d’évaluation"
                value-type="format"
                format="YYYY-MM-DD H:m:s"
                type="datetime"
                :default-value="new Date()"
                class="mb-3"
                placeholder="Renseigner la date/l’heure de la séance d’évaluation"
              ></date-picker>
            </v-card-text>
            <v-card-actions>
              <v-btn small color="primary" class="white--text" block @click="financialProp()">
                PROGRAMMER LA SEANCE D’EVALUATION
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <v-card class="mt-4" v-if="profile != 2">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h6" v-bind="attrs" v-on="on">Liste/nombre/qualité des membres de la commission d’évaluation</h4>
              </template>
              <span>Liste/nombre/qualité des membres de la commission d’évaluation</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-chip class="primary">
              {{ this.$store.getters["Auth/get_selected_evaluation"].users_Commission.length }}
            </v-chip>
          </v-card-title>
          <v-card-text>
            <v-virtual-scroll
              :items="this.$store.getters['Auth/get_selected_evaluation'].users_Commission"
              :item-height="50"
              height="110"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <!-- <v-icon large color="primary" v-show="JSON.parse(localStorage.getItem('userData')).id == item.id">mdi-account-circle</v-icon> -->
                    <v-icon medium>mdi-account-circle</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.user.firstname }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.user.lastname }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <span>{{ item.type.description }}</span>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>

        <v-card
          class="mt-2"
          v-if="
            profile == 4 && !this.$store.getters['Auth/get_selected_evaluation'].signature_state
          "
        >
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h4" v-bind="attrs" v-on="on">Signature du rapport d’évaluation par les membres de la commission</h4>
              </template>
              <span>Signature du rapport d’évaluation par les membres de la commission</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="undo" class="white--text" icon>
              <v-icon>
                mdi-backup-restore
              </v-icon>Reprendre la signature
            </v-btn>
          </v-card-title>
          <v-card-text>
            <VueSignaturePad
              class="mx-auto"
              style="border:1px solid red"
              height="120px"
              width="100%"
              :options="{ penColor: 'rgba(0,43,89)', minWidth: 0.2, maxWidth: 1.5 }"
              ref="signaturePad"
            />
            <div></div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="save" class="white--text" block>
              <v-icon class="mr-2" dark>mdi-draw</v-icon>VALIDER LA SIGNATURE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="profile != 2">
        <v-card>
          <v-card-title>
            <h4>Rédaction du rapport de l’évaluation des Offres/Propositions/Plis/Manifestations d’Intérêts/Pré-qualifications</h4>
            <v-spacer></v-spacer>
            <span v-show="!this.$store.getters['Auth/get_selected_evaluation'].eval.generatedSign">
              <v-btn
                v-show="this.$store.getters['Auth/get_selected_evaluation'].pv"
                @click.prevent="GenerateSignature()"
                text
                depressed
                class="secondary"
              >
              VALIDER LE RAPPORT D’EVALUATION  FINAL SIGNE
              </v-btn>
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!this.$store.getters['Auth/get_selected_evaluation'].pv">
            <DynamicEval
              :key="this.id"
              :evalID="this.id"
              :sessionPV="this.$store.getters['Auth/get_selected_evaluation'].pv_os.file_blob"
              :evalTechPV="
                this.$store.getters['Auth/get_selected_evaluation'].pv_eval_prop_tech
                  ? this.$store.getters['Auth/get_selected_evaluation'].pv_eval_prop_tech
                  : ''
              "
              :sessionType="this.$store.getters['Auth/get_selected_evaluation'].evaluation_type"
            />
          </v-card-text>
          <v-card-text v-if="this.$store.getters['Auth/get_selected_evaluation'].pv">
            <EditorViewer
              v-if="profile == 3"
              :isAllowedPrint="true"
              :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_evaluation'].pv"
            />
            <EditorViewer
              v-if="profile != 3"
              :isAllowedPrint="false"
              :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_evaluation'].pv"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="profile == 2">
        <v-card>
          <v-card-title>
            <h4>Le Rapport de l'evaluation</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="this.$store.getters['Auth/get_selected_evaluation'].pv">
            <EditorViewer
              :isAllowedPrint="false"
              :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_evaluation'].pv"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DynamicEval from "../../../config/DynamicEval";
import EditorViewer from "../../../config/EditorViewer";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { EditorViewer, DynamicEval, DatePicker },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    userData: "",
    evaluation: {
      title: "",
      com: 0,
      document: 0,
      date: null,
      dp_selection_quality: 0
    },
    com: {
      file: [],
      allSign: [],
      GeneratedSignListCom: "",
      com: 0
    },
    AllFinancialType: [
      {
        id: 0,
        object: "Sélection basée sur la qualification technique et le coût / le budget déterminé/le moindre coût/la qualité"
      },
      {
        id: 1,
        object: "Sélection basée sur la qualité de la proposition technique uniquement"
      }
    ],
    docorNoticeID: 0,
    pv: [],
    ppm: [],
    additif: false
  }),
  computed: {
    ...mapGetters({
      get_eval_signature: "Auth/get_eval_signature",
      get_sync_so: "Auth/get_sync_so"
    })
  },
  created() {
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.userData = Virt;
    this.$store.dispatch("Auth/getEval", this.id);
    this.$store.dispatch("Auth/loadAllCom");
  },
  mounted() {
    this.$store.dispatch("Auth/getEval", this.id);
  },
  methods: {
    ...mapActions({
      loadEvalSignature: "Auth/loadEvalSignature"
    }),
    undo() {
      this.$refs.signaturePad.clearSignature();
    },
    async UpdateEvalPv(contentReceived, signature) {
      const data = new FormData();
      let content = contentReceived;
      content += signature;
      data.append("file_blob", content);
      data.append("eval_id", this.id);
      await this.$store.dispatch("Auth/addPV", data);
      // this.$router.go()
    },
    async GenerateSignature() {
      let signat = [];
      await this.loadEvalSignature(this.id).then(() => {
        new Promise(r => setTimeout(r, 2000));
        signat = this.$store.getters["Auth/get_eval_signature"];
      });
      this.allSign = signat.data.data;
      // console.log(this.allSign);
      this.getThem();
    },
    async getThem() {
      let contener = "";
      let session = this.id;

      let collected_signature = this.allSign.filter(function(value, index, arr) {
        return value.eval.id == session;
      }, session);
      let AllCom = collected_signature.filter(function(value, index, arr) {
        return value.com;
      }, session);

      AllCom.forEach((element, index, arr) => {
        this.GeneratedSignListCom += `<tr>
            <td>${index + 1}</td>
            <td>${element.name} ${element.surname}</td>
            <td>${element.fonction}</td>
            <td>${element.quality}</td>
            <td>
              <img width="277" src="${element.fileBlob}" alt="Signature ${element.id}" />
            </td>
          </tr>`;
      });

      let signatureCom = `<p>
      <strong>Annexe 1 : Liste de présence des membres de la comission</strong></p>
      <div class="tableWrapper">
            <table style="min-width: 50px;">
              <tbody>
                <tr>
                  <th><p>N°</p></th>
                  <th><p>Nom et prénoms</p></th>
                  <th><p>Fonction/Structure</p></th>
                  <th><p> Qualité du membre de la commission</p></th>
                  <th><p>Signature</p></th>
                </tr>
                ${this.GeneratedSignListCom}
              </tbody>
            </table>
        </div>
        <p><br class="ProseMirror-trailingBreak"></p>`;

      contener += signatureCom;

      // console.log(this.$store.getters['Auth/get_selected_evaluation'].pv.file_blob)

      this.UpdateEvalPv(this.$store.getters["Auth/get_selected_evaluation"].pv.file_blob, contener);
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      const dataSign = new FormData();
      let userEmail = this.userData.email;
      let ChartTitle = this.$store.getters["Auth/get_selected_evaluation"].users_Commission;
      let UserTitle = ChartTitle.filter(function(value, index, arr) {
        return value.user.email == userEmail;
      }, userEmail);
      dataSign.append("file_blob", data);
      dataSign.append("eval_id", this.id);
      // PROFILE SIGNING
      dataSign.append("is_rep", 0);
      dataSign.append("is_op", 0);
      dataSign.append("is_com", 1);
      // DATA TO SEND
      dataSign.append("name", this.userData.firstname);
      dataSign.append("surname", this.userData.lastname);
      dataSign.append("email", this.userData.email);
      dataSign.append("fonction", this.userData.profession);
      dataSign.append("quality", UserTitle[0].type.description);

      if (!isEmpty) {
        this.$store.dispatch("Auth/addEvalSignature", dataSign);
        // console.log(UserTitle[0].type.description);
      } else {
        //alert swal sign empty
      }
    },
    financialProp() {
      const data = new FormData();
      data.append(
        "docment_id",
        this.$store.getters["Auth/get_selected_evaluation"].eval.document.id
      );
      data.append("commission_id", this.evaluation.com);
      data.append("title", this.evaluation.title);
      data.append("start_time", this.evaluation.date);
      data.append("dp_selection_quality", this.evaluation.dp_selection_quality);
      this.$store.dispatch("Auth/financialFinAdd", data);
      // console.log(this.evaluation.dp_selection_quality,this.$store.getters['Auth/get_selected_evaluation'].eval.document.id)
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
</style>
